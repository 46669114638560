/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *---------------------------------------------------
 */
//Standard libraries
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";
//Custom libraries
import styled from "styled-components";
import { StyledBox } from "../box";
import { StyledProgressPlaceholder } from "../circular-progress";
import { StyledTypography } from "../typography";
import { StyledCard, StyledCardMedia } from "../card";

const StyledProductCard = styled(({ className, ...props }) =>
  props.to ? <Link className={className} {...props} /> : <StyledBox className={className} {...props} />
)`
  ${({ theme }) => `
    display: block;
    height: 100%;

    &.product-grid {
      .MuiCard-root {
        height: 100%;
      }
    }
  `}
`;

interface ProductCardProps {
  seoUrl: any;
  name: any;
  thumbnail: any;
  thumbnailLoading?: boolean;
  price?: number | null;
  swatches: any[];
  catentryId?: string;
  onClick?: any;
  actions?: any;
  className?: any;
  categoryId?: string;
  formattedPriceDisplay: any;
  link?: any;
}

/**
 * Product Card component
 * displays catentry image, name, price, etc
 * @param props
 */
function ProductCard(props: ProductCardProps) {
  const {
    seoUrl,
    name,
    thumbnail,
    categoryId = "",
    thumbnailLoading = false,
    price = null,
    swatches,
    catentryId = "",
    formattedPriceDisplay,
    actions = null,
    link = null,
  } = props;

  const to = useMemo(() => {
    if (link) {
      return link;
    } else {
      return {
        pathname: seoUrl,
        state: { categoryId: categoryId },
      };
    }
  }, [link, categoryId, seoUrl]);

  const onClickAction: any = props.onClick ? { onClick: props.onClick } : {};
  const contentComponent = (
    <>
      {thumbnailLoading ? (
        <StyledProgressPlaceholder className="vertical-padding-8" />
      ) : (
        <LazyLoadComponent visibleByDefault={(window as any).__isPrerender__ || false}>
          <StyledCardMedia image={thumbnail} title={name} component="div" />
        </LazyLoadComponent>
      )}
      <StyledTypography variant="body2" align="center" className="bottom-margin-1 wrapText">
        {name}
      </StyledTypography>
      {swatches.length > 0 && <StyledTypography align="center">{swatches}</StyledTypography>}
      {price && (
        <StyledTypography variant="body1" align="center">
          {formattedPriceDisplay}
        </StyledTypography>
      )}
    </>
  );

  return (
    <StyledProductCard
      className="product-card"
      to={to}
      id={catentryId ? `productCard_a_1_${catentryId}` : ""}
      {...onClickAction}>
      <StyledCard className="product-card" contentComponent={contentComponent} cardActions={actions} />
    </StyledProductCard>
  );
}
export default ProductCard;
