/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries

import { Link } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";

//UI
import {
  StyledGrid,
  StyledButton,
  StyledTypography,
  StyledPaper,
  StyledSwatch,
  StyledProgressPlaceholder,
} from "../../elements";

interface FeaturedCardProps {
  productInfo: any;
  displayPrice: number;
  offerPrice: number;
  attributeList: Array<any>;
  shopNow: string;
  informMarketingOfClick: any;
  formattedPriceDisplay: any;
}

export const FeatureCard = (props: FeaturedCardProps) => {
  const {
    productInfo,
    displayPrice,
    offerPrice,
    attributeList,
    shopNow,
    informMarketingOfClick,
    formattedPriceDisplay,
  } = props;
  return (
    <>
      {productInfo && (
        <StyledPaper
          id={`featureCard_${productInfo.id}`}
          className="horizontal-padding-2 vertical-padding-4">
          <StyledGrid container alignItems="center" spacing={3}>
            <LazyLoadComponent
              placeholder={
                <StyledProgressPlaceholder className="vertical-padding-20" />
              }>
              <StyledGrid
                item
                xs={12}
                sm={6}
                lg={5}
                id={`featureCard_imageContainer_${productInfo.id}`}
                className="product-full-image">
                <Link
                  onClick={informMarketingOfClick}
                  id={`featureCard_imageRouter_${productInfo.id}`}
                  to={productInfo.seo?.href}>
                  <img
                    id={`featureCard_fullImage_${productInfo.id}`}
                    src={productInfo.fullImage}
                    alt={productInfo.name}
                  />
                </Link>
              </StyledGrid>
            </LazyLoadComponent>
            <StyledGrid
              item
              xs={12}
              sm={6}
              md={5}
              lg={6}
              id={`featureCard_describer_${productInfo.id}`}
              className="product-description">
              {productInfo.manufacturer && (
                <StyledTypography variant="overline">
                  {productInfo.manufacturer}
                </StyledTypography>
              )}
              <StyledTypography
                variant="h2"
                id={`featureCard_describer_${productInfo.id}`}>
                {productInfo.name}
              </StyledTypography>
              <div className="vertical-margin-2">
                {attributeList &&
                  attributeList.map((e: any, index: number) => (
                    <StyledSwatch
                      style={{
                        backgroundImage: `url("${e.image1path}")`,
                      }}
                      key={index}
                      className="product-swatch-medium"
                    />
                  ))}
              </div>
              <StyledTypography
                variant="subtitle2"
                id={`featureCard_description_${productInfo.id}`}
                className="text-content short-description">
                {productInfo.shortDescription}
              </StyledTypography>
              <StyledTypography
                variant="h4"
                className="feature-price vertical-margin-2"
                id={`featureCard_price_${productInfo.id}`}>
                {offerPrice && offerPrice > 0 ? (
                  <span>{formattedPriceDisplay(offerPrice)}</span>
                ) : (
                  <span>{formattedPriceDisplay(null)}</span>
                )}
              </StyledTypography>
              <Link
                onClick={informMarketingOfClick}
                id={`featureCard_textRouter_${productInfo.id}`}
                to={productInfo.seo?.href}>
                <StyledButton color="secondary">{shopNow}</StyledButton>
              </Link>
            </StyledGrid>
          </StyledGrid>
        </StyledPaper>
      )}
    </>
  );
};
