/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *---------------------------------------------------
 */

import { StyledIconButton } from "../../elements";
import { useTableUtils } from "../../hooks";
import { useCustomTable } from "./custom-table-context";
import { get, set } from "lodash-es";
import Closed from "@material-ui/icons/ChevronRight";
import Open from "@material-ui/icons/ExpandMoreOutlined";
import { TableConstants } from "../../types";

export const PanelExpanderCell = ({ rowData, headers, ...props }) => {
  const { tableState: s, setTableState } = useCustomTable();
  const { getRowKey } = useTableUtils();
  const k = getRowKey(rowData, headers);
  const noPanel = s[k] && s[k][TableConstants.NOPANEL];
  return (
    <StyledIconButton
      color="primary"
      disabled={noPanel}
      onClick={() => {
        const newState = !get(s, `${k}.${TableConstants.PANEL}`, false);
        set(s, `${k}.${TableConstants.PANEL}`, newState);
        setTableState({ ...s });
      }}>
      {noPanel ? null : get(s, `${k}.${TableConstants.PANEL}`) ? <Open /> : <Closed />}
    </StyledIconButton>
  );
};
