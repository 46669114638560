/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import { Link } from "react-router-dom";
import { CustomTable } from "../../components";
//UI
import { StyledButton } from "../../elements";

type SkuListWidgetProps = {
  productDetails: any;
  [extraProps: string]: any;
};

/**
 * B2B SKU table content display component
 * @description Displays product information ,prize ,quantity ,detailed info for each sku.
 * ` @prop { any } productDetails` The product details object has following properties:
 * ` @property { any } productPartNumber (required)` The productPartNumber of product in string.
 * ` @property { object } translation (required)`  A object which holds multiple string values for transation purpose .
 * ` @property { any[] } tableHeaderData (required)` contains State data for header part of table.
 * ` @property { any[] } tableBodyData (required)` contains State data for table body .
 * ` @property { any[] } tableDetailHeaderData (required)` contains state data for table details header .
 * ` @property { any } loginNotRequired (required)` flag indicate whether login is required or not.
 * ` @property { Function } addToCart (required)`function for Adding the selected product and its quantities to the shopping cart
 * ` @property { boolean } disabledButtonFlag (required)` flag for disabled button.
 * ` @property { string } SIGNIN (required)` Sign In is string for path .
 */
export const SkuListWidget: React.FC<SkuListWidgetProps> = ({
  productDetails,
  ...props
}) => {
  const {
    productPartNumber,
    translation,
    loginNotRequired,
    addToCart,
    disabledButtonFlag,
    SIGNIN,
    tableData,
    isSharedOrder,
  } = productDetails;

  return tableData?.data?.length > 0 ? (
    <>
      <CustomTable {...tableData} />
      <p>
        {loginNotRequired ? (
          <StyledButton
            color="primary"
            size="small"
            className="product-add-to-cart"
            id={`product_add_to_cart_${productPartNumber}`}
            onClick={addToCart}
            disabled={disabledButtonFlag}
            style={{ float: "right" }}>
            {isSharedOrder
              ? translation.productDetailaddToSharedOrder
              : translation.productDetailaddToCurrentOrder}
          </StyledButton>
        ) : (
          <StyledButton
            color="primary"
            size="small"
            className="product-add-to-cart"
            component={Link}
            to={SIGNIN}
            style={{ float: "right" }}>
            {translation.productDetailSignIn}
          </StyledButton>
        )}
      </p>
    </>
  ) : null;
};
