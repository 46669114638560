/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React from "react";
//UI
import { StyledProductImage } from "../../elements/product-image";

interface ProductB2BImageProps {
  thumbnail?: string;
  isAngleImage?: Boolean;
  alt?: string;
}

/**
 * B2B Product image component
 * @param thumbnail
 * @param isAngleImage
 * @param alt
 */
export const ProductB2BImage: React.FC<ProductB2BImageProps> = ({
  thumbnail,
  isAngleImage,
  alt,
  ...props
}) => {
  if (!isAngleImage && thumbnail) {
    return <StyledProductImage itemProp="image" src={thumbnail} alt={alt} />;
  } else {
    return null;
  }
};
