/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import Hidden from "@material-ui/core/Hidden";
//UI
import {
  StyledGrid,
  StyledTypography,
  StyledPDPContainer,
  StyledTabs,
  PriceDisplay,
} from "../../elements";
import { ProductB2BImage } from "../../components/product-image";

type ProductInformationWidgetProps = {
  productDetails: any;
  [extraProps: string]: any;
};

/**
 * B2B Product information display component
 * @description Displays product information ,prize ,image and other product details.
 * ` @prop { any } productDetails` The product details object has following properties:
 * ` @property { any } productData (required)` productData of product.
 * ` @property { object } translation (required)`  A object which holds multiple string values for transation purpose .
 * ` @property { any[] } promotion (required)` A map to hold state of associated promotions.
 * ` @property { any } productPartNumber (required)` The productPartNumber of product in string.
 * ` @property { string } productType (required)` A object which holds productType .
 * ` @property { number } productOfferPrice (required)` A number holds product offer prize .
 * ` @property { number } prodDisplayPrice (required)` A number holds product dispay prize .
 * ` @property {  ITabs[] } productDetailTabsChildren (required)` array of styled elements for productDetailTabsChildren.
 * ` @property {  object } currentProdSelect (required)` A object holds current selected product.
 * ` @property {  string } defaultCurrencyID (required)` A string holds default currency id.
 * ` @property {  string } language (required)` A string holds language id.
 * ` @property {  number } max (optional)` A number holds product max price.
 */
export const ProductInformationWidget: React.FC<ProductInformationWidgetProps> =
  ({ productDetails, ...props }) => {
    const productPartNumber = productDetails.productPartNumber;
    const translation = productDetails.translation;
    const productData = productDetails.productData;
    const promotion = productDetails.promotion;
    const productType = productDetails.productType;
    const productOfferPrice = productDetails.productOfferPrice;
    const prodDisplayPrice = productDetails.prodDisplayPrice;
    const productDetailTabsChildren = productDetails.productDetailTabsChildren;
    const currentProdSelect = productDetails.currentProdSelect;
    const currency = productDetails.defaultCurrencyID
      ? productDetails.defaultCurrencyID
      : "";
    const message = translation.productDetailPriceDisplayPending;
    const language = productDetails.language;
    const max = productDetails.max ? productDetails.max : null;
    return (
      <StyledPDPContainer
        itemScope
        itemType="http://schema.org/Product"
        id={`product-image-details_${productPartNumber}`}>
        <StyledGrid container spacing={2}>
          <Hidden smUp>
            <StyledGrid item xs={1}></StyledGrid>
            <StyledGrid item xs={10} className="product-image">
              {currentProdSelect?.partNumber?.thumbnail ? (
                <ProductB2BImage
                  thumbnail={currentProdSelect.partNumber.thumbnail}
                  isAngleImage={false}
                  alt={currentProdSelect.partNumber.name}
                />
              ) : (
                <ProductB2BImage
                  thumbnail={productData.thumbnail}
                  isAngleImage={false}
                  alt={productData.name}
                />
              )}
            </StyledGrid>
          </Hidden>
          <StyledGrid item xs={12} sm={6} md={6} lg={6} xl={5}>
            <StyledTypography
              variant="h4"
              itemProp="name"
              className="product-name">
              {productData.name}
            </StyledTypography>
            <StyledTypography variant="body2" className="product-sku">
              {translation.productDetailSKU}: {productData.partNumber}
            </StyledTypography>
            <StyledTypography
              variant="body1"
              itemProp="description"
              className="product-shortDescription">
              {productData.shortDescription}
            </StyledTypography>
            {promotion ? (
              <StyledTypography
                variant="body2"
                id={`product_advertisement_${productPartNumber}`}
                className="product-promo"
                gutterBottom>
                {promotion}
              </StyledTypography>
            ) : null}
            <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
              {productType !== "bundle" ? (
                <StyledTypography
                  variant="h5"
                  className="product-price-container">
                  {productOfferPrice > 0 ? (
                    <span className="product-price">
                      <PriceDisplay
                        {...{
                          min: productOfferPrice,
                          max,
                          currency,
                          message,
                          language,
                        }}
                      />
                    </span>
                  ) : null}
                  {productOfferPrice > 0 && prodDisplayPrice > 0 ? (
                    <span
                      id={`product_price_${productPartNumber}`}
                      className={productOfferPrice > 0 ? "strikethrough" : ""}>
                      <PriceDisplay
                        {...{
                          min: prodDisplayPrice,
                          max,
                          currency,
                          message,
                          language,
                        }}
                      />
                    </span>
                  ) : null}
                  {productOfferPrice === 0 ? (
                    <span id={`product_offer_price_${productPartNumber}`}>
                      <PriceDisplay
                        {...{
                          min: null,
                          max,
                          currency,
                          message,
                          language,
                        }}
                      />
                    </span>
                  ) : null}
                </StyledTypography>
              ) : null}
            </div>
            {productDetailTabsChildren?.length > 0 ? (
              <StyledTabs
                childrenList={productDetailTabsChildren}
                name="productDetails"
              />
            ) : null}
          </StyledGrid>

          <Hidden xsDown>
            <StyledGrid item xs={6} md={5} className="product-imageB2B">
              {currentProdSelect?.partNumber?.thumbnail ? (
                <ProductB2BImage
                  thumbnail={currentProdSelect.partNumber.thumbnail}
                  isAngleImage={false}
                  alt={currentProdSelect.partNumber.name}
                />
              ) : (
                <ProductB2BImage
                  thumbnail={productData.thumbnail}
                  isAngleImage={false}
                  alt={productData.name}
                />
              )}
            </StyledGrid>
          </Hidden>
        </StyledGrid>
      </StyledPDPContainer>
    );
  };
